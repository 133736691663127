import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../_utils/actions";
import api from "../../ApplicantProfile/_api/index";
import { addSnackbarMessage } from "../../Common/_plugin/error-response";

/**
 * @memberof module:Common
 * @namespace user_store
 * @description store for user component
 */
export default {
  namespaced: true,

  state: {
    loggedInUser: null,
    permissions: {},
    commonHeader: {},
    profilePic: null,
    lastUrlIndex: null,
  },

  /**
   * @memberof module:Common.user_store
   * @namespace getters
   */
  getters: {
    loggedInUser(state) {
      return state.loggedInUser;
    },
    permissions(state) {
      return state.permissions;
    },
    fetchProfilePic(state) {
      return state.profilePic;
    },
    commonHeader(state) {
      return state.commonHeader;
    },
    lastUrlIndex(state) {
      return state.lastUrlIndex;
    },
  },

  /**
   * @memberof module:Common.user_store
   * @namespace actions
   */
  actions: {
    setLoggedInUser(context, data) {
      context.commit("SET_LOGGED_IN_USER", data);
    },
    setPermissions(context, data) {
      const permissionObject = {};

      data.forEach((permission) => {
        permissionObject[permission.p_title] = permission;
      });

      context.commit("SET_PERMISSIONS", permissionObject);

      const employee = {
        type: "option",
        permission: true,
        options: {
          home: {
            key: "home",
            name: "homepage",
            type: "page",
            permission: true,
            alias: ["home", "homepage"],
            showInSidebar: true,
            showInSearch: true,
            options: {
              applications: {
                key: "Applications",
                type: "option",
                name: "applications",
                iconClass: "fa fa-user",
                alias: ["applications", "applicant"],
                showInSidebar: true,
                showInSearch: true,
                permission: true,
                options: {
                  dashboard: {
                    key: "Dashboard",
                    name: "homepage",
                    type: "page",
                    alias: ["homepage", "dashboard"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: true,
                  },
                  schedule: {
                    key: "Schedule",
                    name: "applicantSchedule",
                    type: "page",
                    alias: ["schedule", "applicant schedule"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: true,
                  },
                  applicants: {
                    key: "Applicant Forms",
                    name: "applicants",
                    type: "page",
                    alias: ["applicant", "applicant forms"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [
                        PERMISSIONS.APPLICANT.READ,
                        PERMISSIONS.APPLICANT["APPLICANT-SEARCH"],
                      ],
                      checkAll: true,
                    }),
                    options: {
                      singleApplicant: {
                        name: "applicantprofile",
                        type: "page",
                        alias: ["applicant profile", "single applicant"],
                        permission: hasAccess({
                          permissions: [PERMISSIONS.APPLICANT.READ],
                        }),
                        options: {
                          editApplicant: {
                            key: "Edit Profile",
                            name: "editApplicantData",
                            type: "page",
                            alias: ["edit profile", "edit Applicant Profile"],
                            permission: hasAccess({
                              permissions: [PERMISSIONS.APPLICANT.UPDATE],
                            }),
                          },
                        },
                      },
                      resolve: {
                        key: "Resolve",
                        name: "resolveduplicate",
                        type: "page",
                        alias: ["applicant resolve", "resolve duplicate"],
                        permission: hasAccess({
                          permissions: [PERMISSIONS.APPLICANT.READ],
                        }),
                      },
                    },
                  },
                  followUps: {
                    key: "Follow Ups",
                    name: "applicantFollowup",
                    type: "page",
                    alias: ["applicant follow up", "follow up"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [
                        PERMISSIONS.APPLICANT.READ,
                        PERMISSIONS.APPLICANT["FOLLOW-UP"],
                      ],
                      checkAll: true,
                    }),
                  },
                  tracker: {
                    key: "Tracker",
                    name: "applicantTracker",
                    type: "page",
                    alias: ["tracker", "applicant tracker"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [
                        PERMISSIONS.APPLICANT.READ,
                        PERMISSIONS.APPLICANT["APPLICANT-TRACKER"],
                      ],
                      checkAll: true,
                    }),
                  },
                  linkRequests: {
                    key: "Link Requests",
                    name: "applicantLinkRequests",
                    type: "page",
                    alias: ["Link Requests", "applicantLinkRequests"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [
                        PERMISSIONS.APPLICANT["LINK-REQUESTS"].MENU,
                      ],
                    }),
                  },
                },
              },
              manage: {
                key: "Manage",
                type: "option",
                name: "manage",
                iconClass: "fa fa-handshake-o",
                alias: ["manage"],
                showInSidebar: true,
                showInSearch: true,
                permission: hasAccess({
                  permissions: [
                    PERMISSIONS.JOB.MENU,
                    PERMISSIONS.REVIEW.MENU,
                    PERMISSIONS.FORM.MENU,
                    PERMISSIONS["CONFIGURATION"].MENU,
                    PERMISSIONS["SHARED-TOKEN"].MENU,
                  ],
                  checkAll: false,
                }),
                options: {
                  jobs: {
                    key: "Jobs",
                    name: "managejobs",
                    type: "page",
                    alias: ["manage jobs"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [PERMISSIONS.JOB.MENU],
                    }),
                  },
                  reviews: {
                    key: "Reviews",
                    name: "managereviews",
                    type: "page",
                    alias: ["manage reviews"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [PERMISSIONS.REVIEW.MENU],
                    }),
                  },
                  applicantMaster: {
                    key: "Applicants",
                    name: "applicantMaster",
                    type: "page",
                    alias: ["applicant master", "handle applicant"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [
                        PERMISSIONS.APPLICANT.READ,
                        PERMISSIONS.APPLICANT["APPLICANT-SEARCH"],
                      ],
                      checkAll: true,
                    }),
                  },
                  templates: {
                    key: "Templates",
                    name: "manageForms",
                    alias: ["templates", "manage forms"],
                    type: "page",
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [PERMISSIONS.FORM.MENU],
                    }),
                    options: {
                      templateEditorMV: {
                        name: "editor-MV",
                        type: "page",
                        permission: hasAccess({
                          permissions: [PERMISSIONS.FORM.UPDATE],
                        }),
                      },
                      templateEditorS: {
                        name: "editor-S",
                        type: "page",
                        permission: hasAccess({
                          permissions: [PERMISSIONS.FORM.UPDATE],
                        }),
                      },
                      templatePreview: {
                        key: "Form Preview",
                        name: "preview-S",
                        type: "page",
                        permission: hasAccess({
                          permissions: [PERMISSIONS.FORM.MENU],
                        }),
                      },
                    },
                  },
                  fileTemplates: {
                    key: "File Templates",
                    name: "manageFileTemplates",
                    type: "page",
                    alias: [
                      "create file",
                      "create template",
                      "file templates",
                      "manage file templates",
                    ],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [PERMISSIONS["FILE-TEMPLATE"].MENU],
                    }),
                    options: {
                      createFileTemplate: {
                        key: "Create",
                        name: "create-template",
                        type: "page",
                        permission: hasAccess({
                          permissions: [PERMISSIONS["FILE-TEMPLATE"].CREATE],
                        }),
                      },
                      editFileTemplate: {
                        key: "Edit",
                        name: "edit-template",
                        type: "page",
                        permission: hasAccess({
                          permissions: [PERMISSIONS["FILE-TEMPLATE"].UPDATE],
                        }),
                      },
                    },
                  },
                  configurations: {
                    key: "Configurations",
                    name: "manageSystemConfigs",
                    type: "page",
                    alias: [
                      "configurations",
                      "manage system configurations",
                      "configs",
                    ],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [PERMISSIONS["CONFIGURATION"].MENU],
                    }),
                  },
                  sharedToken: {
                    key: "Shared Tokens",
                    name: "manageSharedTokens",
                    type: "page",
                    alias: ["shared tokens", "manage shared tokens", "tokens"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [PERMISSIONS["SHARED-TOKEN"].MENU],
                    }),
                    options: {
                      createToken: {
                        key: "Create Token",
                        name: "AddToken",
                        type: "page",
                        permission: hasAccess({
                          permissions: [PERMISSIONS["SHARED-TOKEN"].CREATE],
                        }),
                      },
                      editToken: {
                        key: "Edit Token",
                        name: "EditToken",
                        type: "page",
                        permission: hasAccess({
                          permissions: [PERMISSIONS["SHARED-TOKEN"].UPDATE],
                        }),
                      },
                    },
                  },
                  cronJob: {
                    key: "Cron Jobs",
                    name: "cronJobs",
                    type: "page",
                    alias: ["cron jobs", "schedule", "configs"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [PERMISSIONS["CRON-JOB"].MENU],
                    }),
                  },
                },
              },
              mail: {
                key: "Mail",
                name: "mail",
                type: "option",
                iconClass: "fa fa-envelope",
                alias: ["emails", "manage mail"],
                showInSidebar: true,
                showInSearch: true,
                permission: hasAccess({
                  permissions: [
                    PERMISSIONS.MAIL.CREATE,
                    PERMISSIONS.MAIL.TEMPLATE.READ,
                    PERMISSIONS.MAIL.MENU,
                  ],
                  checkAll: false,
                }),
                options: {
                  compose: {
                    key: "Compose",
                    name: "mailbox",
                    type: "page",
                    alias: ["compose mails", "mail boxes"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [
                        PERMISSIONS.MAIL.CREATE,
                        PERMISSIONS.MAIL.TEMPLATE.READ,
                      ],
                      checkAll: false,
                    }),
                  },
                  status: {
                    key: "Mail Status",
                    name: "mailDashboard",
                    type: "page",
                    alias: ["email status", "mail dashboard"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [PERMISSIONS.MAIL.MENU],
                      checkAll: true,
                    }),
                  },
                  htmlTemplate: {
                    key: "Html Templates",
                    name: "htmltemplates",
                    type: "page",
                    alias: ["manage html template"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [PERMISSIONS["HTML-TEMPLATE"].MENU],
                    }),
                  },
                },
              },
              campusDrive: {
                key: "Campus Drive",
                name: "campusDrive",
                type: "option",
                iconClass: "fa fa-graduation-cap",
                alias: ["campus drives"],
                showInSidebar: true,
                showInSearch: true,
                permission: hasAccess({
                  permissions: [
                    PERMISSIONS["CAMPUS-DRIVE"].ENTITY.MENU,
                    PERMISSIONS["CAMPUS-DRIVE"].RECRUITMENT.MENU,
                    PERMISSIONS["CAMPUS-DRIVE"].SCHEDULE.MENU,
                    PERMISSIONS["CAMPUS-DRIVE"]["MOODLE"].MENU,
                  ],
                  checkAll: false,
                }),
                options: {
                  entityDetails: {
                    key: "Entity Details",
                    name: "viewEntity",
                    alias: ["entity details", "view entity"],
                    showInSidebar: true,
                    showInSearch: true,
                    type: "page",
                    permission: hasAccess({
                      permissions: [PERMISSIONS["CAMPUS-DRIVE"].ENTITY.MENU],
                    }),
                    options: {
                      addEntity: {
                        key: "Add Entity",
                        name: "addEntityForm",
                        type: "page",
                        permission: hasAccess({
                          permissions: [
                            PERMISSIONS["CAMPUS-DRIVE"].ENTITY.CREATE,
                          ],
                        }),
                      },
                      viewEntityDetails: {
                        key: "view Entity",
                        name: "viewEntityDetails",
                        type: "page",
                        permission: hasAccess({
                          permissions: [
                            PERMISSIONS["CAMPUS-DRIVE"].ENTITY.MENU,
                          ],
                        }),
                        options: {
                          editEntity: {
                            key: "Update Entity",
                            name: "editEntityForm",
                            type: "page",
                            permission: hasAccess({
                              permissions: [
                                PERMISSIONS["CAMPUS-DRIVE"].ENTITY.UPDATE,
                              ],
                            }),
                          },
                        },
                      },
                    },
                  },
                  campusRecruitments: {
                    key: "Recruitments",
                    name: "drive",
                    type: "page",
                    alias: ["campus recruitments", "drive"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [
                        PERMISSIONS["CAMPUS-DRIVE"].RECRUITMENT.MENU,
                      ],
                    }),
                    options: {
                      singleRecruitment: {
                        name: "registeredApplicantList",
                        type: "page",
                        permission: hasAccess({
                          permissions: [
                            PERMISSIONS["CAMPUS-DRIVE"].RECRUITMENT.MENU,
                          ],
                        }),
                      },
                    },
                  },
                  campusSchedules: {
                    key: "Campus Schedules",
                    name: "RecruitmentCampusSchedule",
                    type: "page",
                    alias: ["campus schedules", "recruitment campus schedule"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [PERMISSIONS["CAMPUS-DRIVE"].SCHEDULE.MENU],
                    }),
                    options: {
                      singleSchedule: {
                        name: "scheduleEventSlot",
                        type: "page",
                        permission: hasAccess({
                          permissions: [
                            PERMISSIONS["CAMPUS-DRIVE"].SCHEDULE.MENU,
                          ],
                        }),
                      },
                    },
                  },
                  moodleConfigurations: {
                    key: "Moodle Configurations",
                    name: "moodle",
                    type: "page",
                    alias: ["moodle configurations"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [PERMISSIONS["CAMPUS-DRIVE"]["MOODLE"].MENU],
                    }),
                  },
                },
              },
              report: {
                key: "Report",
                name: "report",
                type: "option",
                iconClass: "fa fa-flag",
                alias: ["reports"],
                showInSidebar: true,
                showInSearch: true,
                permission: hasAccess({
                  permissions: [
                    PERMISSIONS.REPORT.SESSION.MENU,
                    PERMISSIONS.REPORT.CANDIDATE.MENU,
                    PERMISSIONS["ERROR-LOG"].MENU,
                    PERMISSIONS["AUDIT-LOG"].MENU,
                  ],
                  checkAll: false,
                }),
                options: {
                  sessionReports: {
                    key: "Session Reports",
                    name: "sessionReport",
                    type: "page",
                    alias: ["session reports"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [PERMISSIONS.REPORT.SESSION.MENU],
                    }),
                  },
                  candidateReports: {
                    key: "Candidate Reports",
                    name: "candidateReport",
                    type: "page",
                    alias: ["candidate reports"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [PERMISSIONS.REPORT.CANDIDATE.MENU],
                    }),
                  },
                  errorLogs: {
                    key: "Error Logs",
                    name: "errorLogs",
                    type: "page",
                    alias: ["error logs"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [PERMISSIONS["ERROR-LOG"].MENU],
                    }),
                  },
                  auditTrail: {
                    key: "Audit Trail",
                    name: "auditTrail",
                    type: "page",
                    alias: ["audit trails"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [PERMISSIONS["AUDIT-LOG"].MENU],
                    }),
                    options: {
                      history: {
                        key: "History",
                        name: "auditTrailDetail",
                        type: "page",
                      },
                    },
                  },
                  applicantReport: {
                    key: "Applicant Report",
                    name: "applicantReport",
                    type: "page",
                    alias: ["applicant reports", "applicants report"],
                    showInSidebar: true,
                    showInSearch: true,
                    permission: hasAccess({
                      permissions: [PERMISSIONS.REPORT.APPLICANT.MENU],
                    }),
                  },
                },
              },
              mySchedule: {
                key: "My Schedule",
                name: "scheduleDashboard",
                type: "page",
                alias: ["my schedules"],
                showInSidebar: false,
                showInSearch: false,
                permission: true,
                options: {
                  campusDrive: {
                    key: "Campus Drive",
                    name: "myCampusSchedule",
                    type: "page",
                  },
                },
              },
            },
          },
        },
      };

      context.commit("SET_COMMON_HEADER", employee);
    },
    logoutUser(context) {
      context.commit("SET_LOGGED_IN_USER", null);
    },
    setLastUrlIndex(context, data) {
      context.commit("SET_LAST_URL_INDEX", data);
    },
    async fetchProfilePic(context, id) {
      await api
        .userProfilePicture(id)
        .then((response) => {
          const data = response.data.pic;
          context.commit("SET_PROFILE_PIC", data);
        })
        .catch((error) => {
          addSnackbarMessage(
            error,
            "Problem retrieving Applicants Profile picture!",
            true
          );
        });
    },
  },

  /**
   * @memberof module:Common.user_store
   * @namespace mutations
   */
  mutations: {
    SET_LOGGED_IN_USER(state, data) {
      state.loggedInUser = data;
      state.permissions = {};
    },
    SET_PERMISSIONS(state, data) {
      state.permissions = data;
    },
    SET_LAST_URL_INDEX(state, data) {
      state.lastUrlIndex = data;
    },
    SET_COMMON_HEADER(state, data) {
      state.commonHeader = data;
    },
    SET_PROFILE_PIC(state, data) {
      state.profilePic = data;
    },
  },
};
